import React from "react";

import { Footer3 } from "../components/Footers";
import { Hero2Dense } from "../components/Heros";
import Head from "../head";

// markup
const IndexPage = () => {
  return (
    <main>
      <Head title="Terms &amp; Conditions" />
      <Hero2Dense />

      <section className="bg-slate-light py-12">
        <div className=" max-w-default m-auto leading-9">
          <h1 className="text-desktop-h2 mb-4">Terms &amp; Conditions</h1>

          <p>
            By means of&nbsp;<em>Software-as-a-Service</em>&nbsp;(“SaaS”), we,
            Urlcheckr, offer you the following Software “Urlcheckr” (the
            “Software”). This means that we offer you access to the Software we
            have developed, via the internet. These are the general terms and
            conditions that are always applicable to the use of our Software.
          </p>

          <p>
            If you have any questions, you can contact us by sending an email
            to&nbsp;
            <a
              href="mailto:support@urlcheckr.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              support@urlcheckr.com
            </a>
            .
          </p>

          <p>
            Our address is Speenkruid 11, 8935JS Leeuwarden. We are registered
            with the Chamber of Commerce (&nbsp;
            <em>Kamer van Koophandel</em>&nbsp;) under number: 60574615.
          </p>

          <p>
            We have the right to change these general terms and conditions at
            all times. The latest version of these terms and conditions will
            always apply. Arrangements that deviate from these terms and
            conditions will only be applicable if they have been agreed on by us
            in writing.
          </p>

          <h4 className="text-xl mt-8 mb-2">
            <strong>General</strong>
          </h4>
          <ol className="list-decimal list-inside pl-4">
            <li>
              These terms and conditions apply to every offer and agreement
              between you and us.
            </li>
            <li>
              We shall send you these general terms and conditions at your
              request, free of charge. You can also find them on our website
              https://urlcheckr.com.
            </li>
            <li>
              The invalidity or unenforceability of any provision of this
              agreement shall not affect the validity or enforceability of any
              other provision of this agreement. Any such invalid or
              unenforceable provision shall be replaced by a provision that is
              considered to be valid and enforceable and which’ interpretation
              shall be as close as possible to the intent of the invalid
              provision.
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Proposals and offers</strong>
          </h4>
          <ol className="list-decimal list-inside pl-4">
            <li>
              All our proposals and offers are non-binding, unless expressly
              agreed otherwise. An offer or proposal only applies to the
              assignment specified therein (and not to possible future
              assignments).
            </li>
            <li>
              When you provide us with certain information, we may assume that
              the provided information is correct and we will base the proposal
              on that information.
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Price</strong>
          </h4>
          <ol className="list-decimal list-inside pl-4">
            <li>Offered prices include expenses and taxes.</li>
            <li>
              We have the right to adjust our prices at any given time. The new
              price will then be applicable Thirty days after its announcement.
            </li>
            <li>
              In case you do not agree with the change of price in question, you
              have the right to cancel the agreement within Thirty days days
              after the announcement. The agreement will then terminate on the
              day the new prices become applicable.
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Payment and Collection Charges</strong>
          </h4>
          <ol className="list-decimal list-inside pl-4">
            <li>
              We shall automatically charge your bank account each month around
              the Immediately after signing up for a Premium account.
            </li>
            <li>
              If a payment is due, and you have failed to meet this payment
              obligation, you will automatically be in default. A default notice
              is not required.
            </li>
            <li>
              In case of default:
              <ol className="list-decimal list-inside pl-4">
                <li>
                  we may charge the statutory (commercial) interest. The
                  interest is calculated from the moment that the payment
                  becomes due, until the moment that the amount has been paid in
                  full.
                </li>
                <li>
                  all extrajudicial costs. In case of an invoice up to € 267,
                  these costs will be € 40. In case of a higher invoice amount,
                  the maximum collection fees are as follows:
                  <br />
                  15% on the first € 2,500;
                  <ol className="list-decimal list-inside pl-4">
                    <li>
                      10% on the part that remains thereafter, up to € 5,000;
                    </li>
                    <li>
                      5% on the part that remains thereafter, up to € 10,000;
                    </li>
                    <li>
                      1% on the part that remains thereafter, to € 200,000;
                    </li>
                    <li>
                      0.5% on the remaining part, whereby the total collection
                      fees are maximised to € 6,775.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Use of “Urlcheckr”</strong>
          </h4>

          <ol className="list-decimal list-inside pl-4">
            <li>
              If you want to use our Software, you will have to register
              yourself at our website.
            </li>
            <li>
              You are free to create multiple accounts. You are responsible for
              the use of the accounts you created.
            </li>
            <li>
              Passwords must be treated confidentially and you are responsible
              for choosing a unique and strong password.
            </li>
            <li>
              In order to use our Software, you must have a proper internet
              connection. You are responsible for other internal networks or
              IT-systems if so required to use our Software within your
              organisation.
            </li>
            <li>
              You are responsible for all activities on your account after it
              has been logged in, unless you have reported as soon as becoming
              aware of it that your personal account has been compromised.
            </li>
            <li>
              We have the right to block accounts. We only do this in case we
              have reasonable belief that one or more accounts are used in a
              matter that is against the law or contrary to a provision of these
              terms. Furthermore, we have the right to take any other measures
              we deem adequate, taking into account the circumstances at hand.
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Availability and maintenance of “Urlcheckr”</strong>
          </h4>

          <ol className="list-decimal list-inside pl-4">
            <li>
              We shall ensure that the Software will be kept available for use
              for the entire duration of this agreement. We shall do our best to
              keep the Software up and running 24 hours a day, 7 days a week.
            </li>
            <li>
              We are responsible for the functioning and maintenance of the
              Software. During maintenance, the Software can be unavailable. We
              will notify you of such a maintenance at least 2 days in advance.
              Only in case of emergencies, we will not send you a notification.
            </li>
            <li>
              We have the right to change the Software. This includes, but is
              not limited to, changing, removing or adding certain features or
              functionalities of the Software.
            </li>
            <li>
              We do not guarantee that our Software is completely free of error.
              Please inform us immediately of any errors, bugs or malfunctions
              of the Software. We will then do our utmost best to resolve your
              problem as quickly as possible.
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Third parties</strong>
          </h4>

          <p>
            We have the right to employ third parties to partially perform our
            duties, if we are of the opinion that this is necessary for the due
            exercise of the SaaS Agreement. Articles 7:404 of the Dutch Civil
            Code (uitvoering door bepaalde persoon), 7:407 section 2 DCC
            (hoofdelijke aansprakelijkheid) and 7:409 DCC (overlijden van
            bepaalde persoon) are not applicable.
          </p>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Force Majeure</strong>
          </h4>

          <p>
            We are not liable for any damages in case of force majeure. If the
            force majeure takes place for a period that exceeds One month, this
            agreement can be terminated in writing. In that case, parties have
            no right to recover damages. We will then send you an invoice
            regarding the period in which you have used our Software.
          </p>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Intellectual property</strong>
          </h4>

          <ol className="list-decimal list-inside pl-4">
            <li>
              We (or our licensor or suppliers) are the exclusive owners of all
              existing and future intellectual property, such as copyrights,
              trademarks, design rights, patents, source codes and know-how,
              which rest on our Software or are the fruits of the use of our
              Software.
            </li>
            <li>
              As a user, you only gain the right to use our Software. You cannot
              claim any of the in subsection 1 mentioned intellectual property.
              This is not an exclusive right, which means that we can grant
              others similar rights of use. Furthermore, it is expressly
              forbidden to transfer or license this right to any third party.
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Non-disclosure</strong>
          </h4>

          <p>
            We are obliged not to disclose any of your confidential information
            to third parties unless it is required by a statutory or
            professional obligation. ‘Confidential information’ includes all
            information which you have designated as confidential or which by
            its nature can be classified as confidential. The following
            information shall in any case be regarded as confidential:
          </p>

          <ol className="list-decimal list-inside pl-4">
            <li>
              all information related to research, development, trade secrets or
              information related to company matters.
            </li>
            <li>
              personal data as intended in the General Data Protection
              regulation (GDPR).
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Liability</strong>
          </h4>

          <ol className="list-decimal list-inside pl-4">
            <li>
              You indemnify us for all claims by third parties relating to the
              data that you have collected, saved or, processed by means of our
              Software. We are not liable for the content of the data that you
              have collected, saved or processed within the framework of our
              Software.
            </li>
            <li>
              We are not liable for any damage which is caused by inadequate use
              of our Software.
            </li>
            <li>
              We are only liable for direct damages that are unequivocally
              caused by a shortcoming from our side.
            </li>
            <li>
              Our liability is limited to a maximum sum of €62,00, or the
              maximum amount paid out by our insurer.
            </li>
            <li>
              We undertake the responsibility to ensure that your data will be
              stored safely. We are not liable for the damage or loss of any
              data, for the storage of which we have employed third parties.
            </li>
            <li>
              The limitations set out in this article do not apply if damage is
              the result of a deliberate act or gross negligence from our side.
            </li>
          </ol>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Applicable law</strong>
          </h4>

          <p>Dutch Law</p>

          <h4 className="text-xl mt-8 mb-2">
            <strong>Competent court</strong>
          </h4>

          <p>The court of Noord-Nederland.</p>
        </div>
      </section>

      <Footer3 />
    </main>
  );
};

export default IndexPage;
